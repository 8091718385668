import { motion } from "framer-motion";
import { LoginBackdrop } from ".";
import React, {useRef, useEffect, useState} from 'react'
import VanillaTilt from "vanilla-tilt";
import { useAuth } from "../../contexts/authentication";
import { useHistory } from "react-router-dom";

// Animation
const dropIn = {
    hidden: {
        y: "-100vh",
        opacity: 0,
    },
    visible: {
        y:"0",
        opacity: 1,
        transition: {
            duration: 1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        },
    },
    exit: {
        y: "100vh",
        opacity: 0,
    }
}

// Modal
const LoginModal = ({handleClose, text}) => {
    // Needed for the tilt card design
    const tilt = useRef(null);

    useEffect(() => {
        VanillaTilt.init(tilt.current, {scale: 1, speed: 500, transition: true, max: 5, glare: true, "max-glare": 0.25})
    }, [])
    
    // Needed for the login functionality
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    
    const { login } = useAuth();
    const emailRef = useRef();
    const passwordRef = useRef();
    
    // To be sent to the home page
    const history = useHistory();

    async function handleSubmit(e) {
        // Prevent form from refrehsing itself
        e.preventDefault(); 

        try {
            setError("")
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value);
            history.push("/gallery")
        } catch {
            setError('Der Benutzername oder das Passwort ist falsch.')
        }
        setLoading(false)
    }

    return (
        <div>
            <LoginBackdrop onClick={handleClose}>
                <motion.div 
                    onClick={(e) => e.stopPropagation()}
                    className="modal"
                    variants={dropIn}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    ref={tilt}
                    id="modaldiv"
                >
                        <motion.div className="modal-content" >  
                            <div class="LoginForm">
                                {error && <li><h2 style={{color: "#dd8ca5", fontFamily: 'Montserrat'}}>{error}</h2></li>}  
                                <h1 id="loginstroke">Login</h1>
                                <li><input id="mailLogin" placeholder="Email Adresse" type="email" ref={emailRef}/></li>
                                <li><input id="passwordLogin" placeholder="Passwort" type="password" ref={passwordRef}/></li>
                                <li><input type="button" id="login" disabled={loading} onClick={handleSubmit} name="login" value="Login"/></li>
                            </div>

                        </motion.div>
                </motion.div>
            </LoginBackdrop>
        </div>
    )
}

export default LoginModal
