import React, {useEffect} from 'react'
import { useAuth } from '../../contexts/authentication'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import "./Nav.css"

export default function Nav({currentPage}) {

    useEffect(() => {
        if(currentPage === "ga") {
            document.getElementById("ga_link").classList.add("activeLink")
            document.getElementById("ca_link").classList.remove("activeLink")
            document.getElementById("gr_link").classList.remove("activeLink")
        } else if(currentPage === "ca") {
            document.getElementById("ca_link").classList.add("activeLink")
            document.getElementById("ga_link").classList.remove("activeLink")
            document.getElementById("gr_link").classList.remove("activeLink")
        } else if(currentPage === "gr") {
            document.getElementById("gr_link").classList.add("activeLink")
            document.getElementById("ca_link").classList.remove("activeLink")
            document.getElementById("ga_link").classList.remove("activeLink")
        }
    }, [currentPage])
    
    const { currentUser, logout } = useAuth()
    const email = currentUser ? currentUser.email : "Keine Mail vorhanden"
    const history = useHistory();
    
    async function handleLogout() {
        try {
            await logout()
            localStorage.removeItem('user')
            history.push("/")
        } catch {
        }
    }
    
        return (
            <div>
                <header className="navHeader">
                    <h1>Sabine</h1>
                    <nav className="navBar">
                        <ul className="navLinks">
                            <li><Link id="ga_link" className="" to="/gallery">Galerie</Link></li>
                            <li><Link id="ca_link" to="/calculator">Taschenrechner</Link></li>
                            <li><Link id="gr_link" to="/wishes">Grüße</Link></li>
                        </ul>                                
                    </nav>
                    <div className="LogoutContainer">
                        <span id="login-mail">{email}</span>
                        <input type="button" name="logout" value ="Abmelden" onClick={handleLogout}/>
                    </div>
                </header>
            </div>
        )
}
