import { AuthProvider } from './contexts/authentication';
import { LoginPage } from './components/Login';
import { BrowserRouter, Switch } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { SuperRoute, ChangeRoute } from './routes';

function App() {
  // Workarround to have authorization in the whole app
  return (
    <AuthProvider>
        <BrowserRouter>
          <AnimatePresence>
            <Switch>
              <ChangeRoute exact path="/" component={LoginPage}></ChangeRoute>
            </Switch>
              
            <Switch>
              <SuperRoute></SuperRoute>
            </Switch>
          </AnimatePresence>
        </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
