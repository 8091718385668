import React, { useState } from 'react'
import Nav from '../Nav/Nav';
import './Calculator.css'

export default function Calculator() {
    // Prozentrechner
    const [error, setError] = useState("");
    const [result, setResult] = useState("");

    const [first, setFirst] = useState();
    const [second, setSecond] = useState();

    const handleCompute = () => {
        setError("");
        if(isNaN(first/second)) {
            setError("Das sind aber nicht nur Zahlen.")
            setResult("")
            return
        } else if(second === 0) {
            setError("Durch 0 darfst du nicht teilen.")
            setResult("")
            return
        } else if(first/second === Infinity) {
            setError("Schau nochmal, ob du alles richtig eingegeben hast.")
            setResult("")
            return
        }
        
        const res = ((first/second *100) + "%")
        setResult(res)
    }

    // Dreisatzrechner

    const [d_error, set_d_error] = useState("");
    const [d_result, set_d_result] = useState("");

    const [d_first, set_d_first] = useState();
    const [d_second, set_d_second] = useState();
    const [d_third, set_d_third] = useState();

    const handle_d_compute = () => {
        set_d_error("");
        if(d_first === 0) {
            set_d_error("Wenn die erste Zahl 0 ist, kannst du keine Aussage treffen.")
            set_d_result("0")
            return
        } else if(isNaN(d_second/d_first * d_third)) {
            set_d_error("Da sind auch nicht nur Zahlen drin.")
            set_d_result("");
            return
        }

        const d_res = d_second/d_first * d_third;
        set_d_result(d_res)

    }

    
    return (
        <>
            <Nav currentPage="ca"></Nav>
            
            <div className="Rechner">
                <div className="prozentRechner">
                    <h1>Prozentrechnung</h1>
                    {error && <h1>{error}</h1>}
                    <ul className="list1">
                    <span>1. Zahl : Wie viel? </span><li><input type="text" name="Zahl1" value={first} onInput={e => setFirst(e.target.value)}></input></li>
                    <span>2. Zahl : Wovon?</span><li><input type="text" name="Zahl2" value={second} onInput={e => setSecond(e.target.value)}></input></li>
                    <span>Ergebnis </span><li><input type="text" disabled="true" className="Ergebnis" name="Ergbenis" value={result}></input></li>
                        <li><input type="button" value="Berechne" onClick={handleCompute}></input></li>
                    </ul>
                </div>
                
                <div className="dreisatzRechner">
                    <h1>Dreisatzrechnung</h1>
                    {d_error && <h1>{d_error}</h1>}
                    <ul className="list2">
                        <span>1. Zahl : Wie viel?</span><li><input type="text" name="d_Zahl1" value={d_first} onInput={e => set_d_first(e.target.value)}></input></li>
                        <span>2. Zahl : Entspricht wie viel?</span><li><input type="text" name="d_Zahl2" value={d_second} onInput={e => set_d_second(e.target.value)}></input></li>
                        <span>3. Zahl : Wie viel möchtest du wissen?</span><li><input type="text" name="d_Zahl3" value={d_third} onInput={e => set_d_third(e.target.value)}></input></li>
                        <span>Ergebnis </span><li><input type="text" className="Ergebnis"disabled="true" name="Ergbenisd" value={d_result}></input></li>
                        <li><input type="button" value="Berechne" onClick={handle_d_compute}></input></li>
                    </ul>
                </div>
            </div>
        </>
    )
}
