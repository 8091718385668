import React, {useState} from 'react'
import Nav from '../Nav/Nav'
import {collection, orderBy, limit, query, addDoc, serverTimestamp} from 'firebase/firestore';
import { firestore } from '../../firebase';
import Wish from './Wish';
import { useCollectionData } from 'react-firebase9-hooks/firestore'
import { auth } from '../../firebase';
import './Wishes.css'

export default function Wishes() {
    
    const messagesRef = collection(firestore, 'messages');
    const q = query(messagesRef, orderBy('createdAt'), limit(25));

    const [messages] = useCollectionData(q, {idField: 'id'});

    // Form in die man seine Nachricht eingibt
    const [formValue, setFormValue] = useState('');

    const sendMessage = async(e) => {
        e.preventDefault();

        if(formValue === ""){
            return
        }
        
        const { uid } = auth.currentUser;
        const email = auth.currentUser.email;

        await addDoc(messagesRef, {
            text: formValue,
            createdAt: serverTimestamp(),
            uid,
            email,
        })
        
        setFormValue('')
    }

    // Dummy for scrolling down when a new message is added
    
    return (
        <>
            <Nav currentPage="gr"/>
            <div className="ChatContainer">
                <div>
                    <div className="Chat">
                        <div className="ChatMessages">
                            {messages && messages.map(msg => <Wish key={msg.id} message={msg} photoURL="https://de.wikipedia.org/wiki/Icon_(Computer)#/media/Datei:Konqueror_large.png"/>)}
                        </div>

                        <form className="ChatActions"onSubmit={sendMessage}>
                            <input value={formValue} onChange={e => setFormValue(e.target.value)}/>
                            <button type="submit">Senden</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
