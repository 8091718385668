import React, {useState} from 'react'
import { motion, AnimatePresence } from "framer-motion";
import {im1,im2,im3,im4,im5,im6,im7,im8,im9,im10,im11,im12,im13,im14,im15,im16,im17,im18,im19,im20} from './Bilder'

export default function Slider() {
    
    const images = [im1,im2,im3,im4,im5,im6,im7,im8,im9,im10,im11,im12,im13,im14,im15,im16,im17,im18,im19,im20]
    
    
    const variants = {
        enter: (direction) => {
          return {
            x: direction > 0 ? 1000 : -1000,
            opacity: 0
          };
        },
        center: {
          zIndex: 1,
          x: 0,
          opacity: 1
        },
        exit: (direction) => {
          return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0
          };
        }
      };

    const swipeConfidenceThreshold = 10000;
    const swipePower = (offset, velocity) => {
            return Math.abs(offset) * velocity;
    };

    const [[page, direction], setPage] = useState([0, 0]);
    
    const imageIndex = Math.abs(page % images.length); 

    const paginate = (newDirection) => {
        setPage([page + newDirection, newDirection]);
      };
    
    
    return (
        <div className="SliderContainer">
        <div className="Slider">
            <AnimatePresence initial={false} custom={direction}>
                <motion.img
                    key={page}
                    src={images[imageIndex]}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                    x: { type: "spring", stiffness: 300, damping: 30 },
                    opacity: { duration: 0.2 }
                    }}
                    drag="x"
                    dragConstraints={{ left: 0, right: 0 }}
                    dragElastic={1}
                    onDragEnd={(e, { offset, velocity }) => {
                    const swipe = swipePower(offset.x, velocity.x);
        
                    if (swipe < -swipeConfidenceThreshold) {
                        paginate(1);
                    } else if (swipe > swipeConfidenceThreshold) {
                        paginate(-1);
                    }
                    }}
                />
            </AnimatePresence>
            <div className="next" onClick={() => paginate(1)}>
            {"🠖"}
            </div>
            <div className="prev" onClick={() => paginate(-1)}>
            {"🠔"}
            </div>
      </div></div>
    )
}
