import React from 'react'
import { auth } from '../../firebase';

export default function Wish(props) {
    const { text, uid, email } = props.message;
    
    const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';
    
    const links = messageClass === 'sent' ? true : false;
    
    return ( 
        <div className={`containerMessage${messageClass}`}>
            {links && <span className={`${messageClass}Mail`}>{email}</span>}
            <div className={`message ${messageClass}`}> 
                <p>{text}</p>
            </div>
            {!links && <span className={`${messageClass}Mail`}>{email}</span>}
        </div>
    )
}
