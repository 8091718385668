import React from 'react'
import { PrivateRoute } from '.'
import Gallery from '../components/Bildergallerie/Gallery'
import Calculator from '../components/Taschenrechner/Calculator'
import Wishes from '../components/Gruesse/Wishes'

export default function SuperRoute() {

    return (
        <>
            <PrivateRoute path ="/gallery"><Gallery/></PrivateRoute>
            <PrivateRoute path ="/calculator"><Calculator/></PrivateRoute>
            <PrivateRoute path ="/wishes"><Wishes/></PrivateRoute>
        </>
    )
}
