import React from 'react'
import { Route, Redirect } from 'react-router'
import { useAuth } from '../contexts/authentication';

export default function ChangeRoute({component : Component, ...rest}) {

    const { currentUser } = useAuth();
    
    return (
        <Route
            {...rest}
            render = {props => {
                return (localStorage.getItem('user') && currentUser) ? <Redirect to="/gallery"/> : <Component {...props} />
            }}
        ></Route>
    )
}
