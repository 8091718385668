import { AnimatePresence, motion } from 'framer-motion';
import React, {useEffect} from 'react'
import { LoginModal } from '.';
import useModal from '../../hooks/useModal';
import './Login.css'
import hoverEffect from 'hover-effect'
import image1 from '../../images/Startseite1.png'
import image2 from '../../images/Startseite2.png'
import displacement from '../../images/7_1.jpg'
import $ from "jquery";

export default function LoginPage() {
    const { loginModalOpen, close, open } = useModal();

    
    useEffect(() => {
      var animation = new hoverEffect({
        parent: document.querySelector('.image'),
        intensity: 0.3,
        image1: image1,
        image2: image2,
        displacementImage: displacement,
        imagesRatio: 1.5,
        speed: 1,
        angle: 1.25*Math.PI,
      }); return animation
    })

    $('.container').mousemove(function(e) {
      $('#cursorBlob').css({
          left: e.pageX-50,
          top: e.pageY-50
        });
    
    })
    
    return (
      
      <div className="container">
        <div className="item">
          <div className="image-header"></div>
              <div>
                <div className="image"></div>
              </div>
        </div>
      
        <div id="cursorBlob"></div>
      
      
      <div className="colorcontainer">
        <div className="textcontainer">
          <h1>Alles Gute Mama.</h1>
          <h1 id="stroke">Alles Gute Mama.</h1>
          <p id="Lied">"Hoch sollst du leben, hoch sollst du leben, hoch sollst du leben, drei mal hoch!" - Jonas</p>
          <p>Ich wünsche dir alles alles Gute zum Geburtstag. Hab einen super schönen Tag, lass es dir mal gut gehen und wie wärs mit einem Glas Wein? Oder zwei? Oder drei? Nun, heute darfst du so viel trinken wie du möchtest, aber immer schön anstoßen. Auf dich.</p>
        </div>
        
      </div>
      
        <div className="button">
          <motion.button
            className="login-button"
            id="startLogin"
            onClick= {open}
            >
            Anmelden
          </motion.button>
        </div>
        <AnimatePresence
            initial={false}
            exitBeforeEnter={true}
          >
              {loginModalOpen && <LoginModal loginModalOpen={loginModalOpen} handleClose={close} />}
          </AnimatePresence>
      </div>
    )
}
