import React from 'react'
import Nav from '../Nav/Nav'
import './Gallery.css'
import Slider from './Slider'

export default function Gallery() {
    return (
        <>
            <Nav currentPage="ga"/>
            <div className="gallery">
                <a href="https://firebasestorage.googleapis.com/v0/b/geschenksabine.appspot.com/o/Bilder.zip?alt=media&token=2a2e7e42-d674-4d7f-9c95-b8f1f08b8436">Downloade die Bilder</a>
            </div>
            <Slider/>
        </>
    )
}
