import {useState} from 'react';

const useModal = () => {
    const[loginModalOpen, setLoginModalOpen] = useState(false);
  
    const close = () => setLoginModalOpen(false);
    const open = () => setLoginModalOpen(true);
  
    return {loginModalOpen, close, open};
  };

  export default useModal;